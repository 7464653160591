import React, { useRef } from "react";
import useScrollVisibility from "../useScrollVisibility";
import { FaGithub } from "react-icons/fa";
import {
  display1,
  display1Webp,
  display2,
  display2Webp,
  display3,
  display3Webp,
  display4,
  display4Webp,
  display5,
  display5Webp,
} from "..";

import "./portfolio.css";

const Portfolio = () => {
  const portfolioRef = useRef(null);
  const isVisible = useScrollVisibility(portfolioRef);

  const supportsWebP = () => {
    const elem = document.createElement("canvas");
    if (!!(elem.getContext && elem.getContext("2d"))) {
      // Check if the browser supports the 'image/webp' format
      return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    }
    return false;
  };

  const renderImage = (webpSrc, fallbackSrc) => {
    return supportsWebP() ? webpSrc : fallbackSrc;
  };

  return (
    <section
      className={`portfolio ${isVisible ? "show-animate" : ""}`}
      id="portfolio"
      ref={portfolioRef}
    >
      <h2 className="heading">
        Latest <span>Project</span>
        <span className="animate scroll" style={{ "--i": 1 }}></span>
      </h2>
      <div className="portfolio-container">
        <div className="portfolio-box">
          <img
            src={renderImage(display2Webp, display2)}
            alt="project_image"
            width="381"
            height="201"
          />
          <div className="portfolio-layer">
            <h2>Gourmet Linx UI/UX</h2>
            <p>
              A responsive mobile restaurant UI/UX,developed with react
              reuseable components.
            </p>
            <a
              href="https://github.com/LinXar-ok/gourmet-linx_react-project.git"
              aria-label="Check this project's GitHub repository"
            >
              <i>
                <FaGithub />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 2 }}></span>
        </div>
        <div className="portfolio-box">
          <img
            src={renderImage(display1Webp, display1)}
            alt="project_image"
            width="384"
            height="201"
          />

          <div className="portfolio-layer">
            <h2>E-commerce Fullstack</h2>
            <p>
              Developed with Material-UI framework and Strapi for the back-end.
            </p>
            <a
              href="https://github.com/LinXar-ok/ecommerce_fullstack-project.git"
              aria-label="Check this project's GitHub repository"
            >
              <i>
                <FaGithub />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 3 }}></span>
        </div>
        <div className="portfolio-box">
          <img
            src={renderImage(display4Webp, display4)}
            alt="project_image"
            width="381"
            height="204"
          />
          <div className="portfolio-layer">
            <h2>Modern Payment UI/UX</h2>
            <p>
              Modern payment UI/UX developed with Tailwind css and Vite. Great
              gradient to bring the design to live.
            </p>
            <a
              href="https://github.com/LinXar-ok/payment-react.git"
              aria-label="Check this project's GitHub repository"
            >
              <i>
                <FaGithub />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 4 }}></span>
        </div>
        <div className="portfolio-box">
          <img
            src={renderImage(display3Webp, display3)}
            alt="project_image"
            width="381"
            height="201"
          />

          <div className="portfolio-layer">
            <h2>GPT-3 UI/UX</h2>
            <p>
              A modern OpenAI UI/UX, great gradient that gives the design that
              3D feel.
            </p>
            <a
              href="https://github.com/LinXar-ok/GPT-3_project.git"
              aria-label="Check this project's GitHub repository"
            >
              <i>
                <FaGithub />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 2 }}></span>
        </div>
        <div className="portfolio-box">
          <img
            src={renderImage(display5Webp, display5)}
            alt="project_image"
            width="384"
            height="204"
          />
          <div className="portfolio-layer">
            <h2>E-commerce BackEnd</h2>
            <p>
              Nextjs project with mongoDB, responsive popups and alerts, fully
              functional and styled with tailwind css.
            </p>
            <a
              href="https://github.com/LinXar-ok/ecommerce_backend-project.git"
              aria-label="Check this project's GitHub repository"
            >
              <i>
                <FaGithub />
              </i>
            </a>
          </div>
          <span className="animate scroll" style={{ "--i": 3 }}></span>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
